import * as ReactDOM from 'react-dom'
import { svrExport } from '@sevenrooms/core/utils'
import { AppRoot } from '@sevenrooms/mgr-core'
import { ListViewActionButtons } from './components/ListViewActionButtons'
import { SwitchToListViewButton } from './components/SwitchToListViewButton'
import { SwitchToWeekViewButton } from './components/SwitchToWeekViewButton'
import { WeekViewActionButtons } from './components/WeekViewActionButtons'
import type { ShiftDetails } from './types/ShiftDetails'

const renderSwitchToWeekViewButton = (elem: HTMLElement) => {
  ReactDOM.render(
    <AppRoot>
      <SwitchToWeekViewButton />
    </AppRoot>,
    elem
  )
}

const renderSwitchToListViewButton = (elem: HTMLElement, dateString: string) => {
  ReactDOM.render(
    <AppRoot>
      <SwitchToListViewButton dateString={dateString} />
    </AppRoot>,
    elem
  )
}

const renderListViewActionButtons = (
  elem: HTMLElement,
  date: Date,
  shiftDetails: ShiftDetails[],
  shiftCategoryOptions: Record<string, string>,
  onClick: () => void
) => {
  ReactDOM.render(
    <AppRoot>
      <ListViewActionButtons onClick={onClick} date={date} shiftDetails={shiftDetails} shiftCategoryOptions={shiftCategoryOptions} />
    </AppRoot>,
    elem
  )
}

const renderWeekViewActionButtons = (elem: HTMLElement, date: Date, onClick: (date: Date) => void) => {
  ReactDOM.render(
    <AppRoot>
      <WeekViewActionButtons date={date} onClick={onClick} />
    </AppRoot>,
    elem
  )
}

svrExport('MgrAccessRules', 'renderSwitchToWeekViewButton', renderSwitchToWeekViewButton)
svrExport('MgrAccessRules', 'renderSwitchToListViewButton', renderSwitchToListViewButton)
svrExport('MgrAccessRules', 'renderListViewActionButtons', renderListViewActionButtons)
svrExport('MgrAccessRules', 'renderWeekViewActionButtons', renderWeekViewActionButtons)
